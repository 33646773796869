.bg {
  background-image: url("./Images/bgcalib.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
}

.MuiOutlinedInput-root {
  font-size: 20px!important;
}