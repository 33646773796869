@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  height: 100%;
  width: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h2 {
  color: black;
  font-size: 30px;
  font-weight: 700;
}

.MuiTypography-root {
  font-family: Montserrat!important ;
  letter-spacing: unset!important;
}

.App-header {
  background-color: #fff;
  height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: normal;
}
.App-header p {
  color: black;
}
.secondary-layout {
  background-color: #fff;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}
td,
th {
  border: 1px solid #000000;
  text-align: left;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #dddddd;
}
tr:nth-child(even) > td > input {
  background-color: #dddddd;
}

.radio-cell {
  text-align: center;
  width: 8%;
}
.button-style {
  width: fit-content;
  height: 7vh;
  flex-shrink: 0;
  background-color: #01216b;
  padding: 0px 10px;
}
p {
  color: black;
}

.button-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: #a0d2f9;
}

.button-background-right {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: #a0d2f9;
}
.button-background-double {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: #a0d2f9;
}
.button-background-triple {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10vh;
  background-color: #a0d2f9;
}
.box-allocate {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 50%;
  position: relative;
  flex-shrink: 0;
  border-radius: 20px;
  background: #a0d1f8;
}

.times-center {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 15%;
  margin: auto;
}
.bg-blue {
  background-color: #a0d2f9;
}

.bg-grey {
  background-color: #d9d9d9;
}
ul {
  list-style-type: disc;
}
.non-ul {
  list-style-type: none;
}
ol {
  list-style-type: decimal;
}
.blue-text {
  color: 1C31EF !important;
}
